.section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 2rem;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    width: 100%;
}

@media (max-width: 767px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}

.left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    padding: 1rem;
    border-radius: .5rem;
    border: 1px solid var(--neutral-200);
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--text-secondary);
}
.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.emoji {
    padding: .5rem;
    border-radius: .75rem;
    background-color: var(--neutral-100);
    width: fit-content;
    height: fit-content;
}
.emoji div {
    font-size: 1.75rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tags {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.tag {
    padding: .25rem .5rem;
    border-radius: .5rem;
    font-size: .85rem;
    font-weight: bold;
    color: white;
}

.title {
    color: var(--text-main);
    font-size: 1.25rem;
    font-weight: 600;
}

.leftBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}
.techContainer {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.techLogo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .5rem;
    padding: .25rem;
    background-color: var(--neutral-100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navContainer button {
    width: 2.5rem;
    height: 2.5rem;
    padding: .25rem;
    cursor: pointer;
    border-radius: 9999px;
    border: 1px solid var(--neutral-200);
    background: transparent;
    color: var(--text-main);
    display: flex;
    align-items: center;
    justify-content: center;
}
.navContainer button img {
    width: 1rem;
    height: 1rem;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    padding: 1rem;
    border-radius: .5rem;
    border: 1px solid var(--neutral-200);
}

.preview {
    border-radius: .5rem;
    width: 100%;
}