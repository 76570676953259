/* Normal */
.input {
    border: 1px solid var(--outline-color);
    outline: none;
    padding: .5rem 1rem;
    background-color: var(--background-color);
    color: var(--text-color);
}
.input:focus {
    border-color: transparent;
    outline: 2px solid var(--outline-color-active);
}
.input:disabled {
    cursor: not-allowed;
    border-color: #8a8a8a;
    color: #8a8a8a;
}
/* Password Toggle */
.toggleVisibility {
    position: absolute;
    display: flex;
    right: 1rem;
    cursor: pointer;
    color: var(--text-color);
    user-select: none;
}
/* Border Radius */
.rounded {
    border-radius: 10rem;
}
.soft {
    border-radius: .5rem;
}
.hard {
    border-radius: 0;
}
/* Material */
.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.inputField {
    border: 1px solid var(--outline-color);
    outline: none;
    position: relative;
    background-color: var(--background-color);
    color: var(--text-color);
}
.inputField:disabled {
    cursor: not-allowed;
    border-color: #8a8a8a;
    color: #8a8a8a;
}
.inputField:disabled + .placeholderText .labelText {
    color: #8a8a8a;
}
.placeholderText {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
}
.labelText {
    font-size: .85rem;
    background-color: transparent;
    transform: translate(0);
    color: var(--text-color);
    transition: 
        transform 0.15s ease-out, 
        font-size 0.15s ease-out, 
        background-color 0.2s ease-out, 
        color 0.15s ease-out;
}
.inputField, .placeholderText {
    font-size: .85rem;
    padding: .5rem 1rem;
}
.inputField:focus {
    border-color: transparent;
    outline: 2px solid var(--outline-color-active);
}
.inputField:focus + .placeholderText .labelText {
    font-size: .75rem;
    padding-inline: .2rem;
    transform: translate(0, -130%);
    border-color: var(--outline-color-active);
    color: var(--outline-color-active);
}
.inputField:focus + .placeholderText .labelText, :not(.inputField[value=""]) + .placeholderText .labelText {
    background-color: var(--material-label-background);
    font-size: .75rem;
    padding-inline: .2rem;
    transform: translate(0, -130%);
}
