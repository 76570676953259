.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, #505050 1px, transparent 1px);
    background-size: 20px 20px;
}
.gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(transparent, var(--bg-main) 70%);
    display: flex;
    align-items: center;
    justify-content: center;
}