.nav {
    padding: .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    border-radius: 9999px;
    background: var(--navbar-color);
    backdrop-filter: blur(8px);
    z-index: 50;
    position: sticky;
    margin: 0 auto;
    top: 1rem;
    width: fit-content;
    font-size: .85rem;
    border: 1px solid var(--neutral-200);
}
.nav a {
    color: var(--text-main);
    text-decoration: none;
}
.nav div {
    color: var(--text-main);
    border: 1px solid transparent;
    padding: .25rem .5rem;
    border-radius: 9999px;
    transition: all .3s ease;
}
.nav div:not(.logo) {
    cursor: pointer;
}
.nav div:hover:not(.logo) {
    background-color: var(--neutral-200);
}

.logo {
    padding: .5rem;
    z-index: 50;
    position: sticky;
    margin: 0 auto;
    top: 1rem;
    width: fit-content;
}

@media (max-width: 767px) {
    .nav {
        display: none;
    }
}

.navbarMobile {
    width: 100%;
    padding: 1rem 2rem;
    height: 3.5rem;
    position: sticky;
    top: 0;
    background-color: var(--bg-main);
    backdrop-filter: blur(8px);
    z-index: 50;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .navbarMobile {
        display: none;
    }
}

.mobileNavButton {
    background: none;
    border: none;
    outline: none;
    color: var(--text-main);
    cursor: pointer;
}

.mobileMenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 50;
    width: 100%;
    background-color: var(--bg-main);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.mobileMenu a {
    color: var(--text-main);
    text-decoration: none;
}
.navContent {
    width: 100%;
    max-width: 1536px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navLeft,
.navRight {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.navContent a {
    color: var(--text-main);
    text-decoration: none;
}