.section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-main);
    padding: 0 0 3rem 0;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
}
.rows {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    max-width: 40rem;
}