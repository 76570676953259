.textWrapper {
    position: relative;
    display: inline-block;
}

.text {
    position: absolute;
    display: inline-block;
    padding: 0.25rem 1rem;
    background: linear-gradient(to right, white, white);
    border-radius: 0.5rem;
    background-repeat: no-repeat;
    background-position: left center;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    z-index: 2;
}

.highlight {
    position: relative;
    display: inline-block;
    padding: 0.25rem 1rem;
    background: linear-gradient(to right, #8f41c5, #ff70c6);
    border-radius: 0.5rem;
    background-repeat: no-repeat;
    background-position: left center;
    color: var(--text-main);
    z-index: 1;
}