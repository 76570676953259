.section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 2rem;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    width: 100%;
}
@media (max-width: 767px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}
.card {
    height: 100%;
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}
.right a {
    color: var(--text-main);
    text-decoration: none;
}