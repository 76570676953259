.section {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}
.container h1 {
    font-size: 4rem;
}
.container h2 {
    font-size: 2rem;
}
.horizontalContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}