/* Normal */
.textarea {
    border: 1px solid var(--outline-color);
    outline: none;
    padding: .5rem 1rem;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
    resize: none;
    height: 12rem;
}
.textarea:focus {
    border-color: transparent;
    outline: 2px solid var(--outline-color-active);
}
.textarea:disabled {
    cursor: not-allowed;
    border-color: #8a8a8a;
    color: #8a8a8a;
}
/* Border Radius */
.soft {
    border-radius: 0.5rem;
}
.hard {
    border-radius: 0;
}
/* Material */
.textareaWrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.textareaField {
    border: 1px solid var(--outline-color);
    outline: none;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
    resize: none;
    height: 12rem;
}
.textareaField:disabled {
    cursor: not-allowed;
    border-color: #8a8a8a;
    color: #8a8a8a;
}
.textareaField:disabled + .placeholderText .labelText {
    color: #8a8a8a;
}
.placeholderText {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
}
.labelText {
    font-size: .85rem;
    background-color: transparent;
    color: var(--text-color);
    transition: 
        transform 0.15s ease-out, 
        font-size 0.15s ease-out, 
        background-color 0.2s ease-out, 
        color 0.15s ease-out;
}
.textareaField, .placeholderText {
    font-size: .85rem;
    padding: .5rem 1rem;
}
.textareaField:focus {
    border-color: transparent;
    outline: 2px solid var(--outline-color-active);
}
.textareaField:focus + .placeholderText .labelText {
    font-size: .75rem;
    padding-inline: .2rem;
    transform: translate(0, -1rem);
    background-color: var(--material-label-background);
    color: var(--outline-color-active);
}
.hasValue .labelText {
    background-color: var(--material-label-background);
    font-size: .75rem;
    padding-inline: .2rem;
    transform: translate(0, -1rem);
}