.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
}
.toggleWrapper {
    display: flex;
    align-items: center;
    gap: .5rem;
    border: 1px solid var(--neutral-200);
    border-radius: 9999px;
    padding: .5rem;
    width: fit-content;
}
.toggleButton {
    background: transparent;
    border: none;
    color: var(--text-main);
    padding: .5rem 1rem;
    cursor: pointer;
    border-radius: 9999px;
    font-weight: bold;
    transition: all .3s ease-in-out;
    border: 1px solid transparent;
}
.toggleButton:hover {
    border-color: #ff70c6;
}
.toggleButton.active {
    border-color: #ff70c6;
    background-color: #ff70c6;
    color: white;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 2rem;
    overflow: hidden;
}
.wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.card {
    height: 100%;
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.experience {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.top {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.logo {
    padding: .5rem;
    background: var(--bg-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .75rem;
}
.logo img {
    height: 2rem;
    width: 2rem;
}
.mainText {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
.weakText {
    color: var(--text-tertiary);
    font-size: .9rem;
}

.bottom {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
}
.verticalLine {
    min-height: 1rem;
    max-width: 1px;
    width: 4px;
    background: var(--neutral-200);
    margin: 0 calc(1.5rem - 1px);
}
.paragraph {
    color: var(--text-tertiary);
    font-size:.9rem;
}