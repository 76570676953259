.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 5rem;
    max-width: 1536px;
    margin: 0 auto;
}
.footerRight,
.footerLeft {
    display: flex;
    align-items: center;
    gap: 1rem;
}