:root {
  --purple-50: #F3EBF9;
  --purple-100: #E8D8F3;
  --purple-200: #D3B5E8;
  --purple-300: #BC8EDC;
  --purple-400: #A467D0;
  --purple-500: #8f41c5;
  --purple-600: #7231A0;
  --purple-700: #562579;
  --purple-800: #3A1952;
  --purple-900: #1C0C27;

  --pink-50: #fff0f9;
  --pink-100: #ffe0f3;
  --pink-200: #ffc7e9;
  --pink-300: #ffa8dc;
  --pink-400: #ff8fd2;
  --pink-500: #ff70c6;
  --pink-600: #ff29a9;
  --pink-700: #db0084;
  --pink-800: #940059;
  --pink-900: #47002b;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --green-50: #ecfdf5;
  --green-100: #d1fae5;
  --green-200: #a7f3d0;
  --green-300: #6ee7b7;
  --green-400: #34d399;
  --green-500: #10b981;
  --green-600: #059669;
  --green-700: #047857;
  --green-800: #065f46;
  --green-900: #064e3b;

  --grey-50: #fafafa;
  --grey-100: #f4f4f5;
  --grey-200: #e4e4e7;
  --grey-300: #d4d4d8;
  --grey-400: #a1a1aa;
  --grey-500: #71717a;
  --grey-600: #52525b;
  --grey-700: #3f3f46;
  --grey-800: #27272a;
  --grey-900: #18181b;
  --grey-950: #09090b;
}

[data-theme="light"] {
  --bg-main: #ffffff;
  --bg-secondary: var(--grey-50);
  --navbar-color: #ffffff95;
  --text-main: #000000;
  --text-secondary: var(--grey-800);
  --text-tertiary: var(--grey-600);
  --text-50: #00000050;
  --text-75: #00000075;
  --navbar: #ffffff95;
  --primary-color: var(--purple-500);
  --primary-color-light: var(--purple-400);
  --primary-color-dark: var(--purple-600);
  --secondary-color: var(--pink-500);
  --secondary-color-light: var(--pink-400);
  --secondary-color-dark: var(--pink-600);
  --error-bg: var(--red-100);
  --error-text: var(--red-700);
  --success-bg: var(--green-100);
  --success-text: var(--green-700);
  --neutral-50: var(--grey-50);
  --neutral-100: var(--grey-100);
  --neutral-200: var(--grey-200);
  --neutral-300: var(--grey-300);
  --neutral-400: var(--grey-400);
  --neutral-500: var(--grey-500);
  --neutral-600: var(--grey-600);
  --neutral-700: var(--grey-700);
  --neutral-800: var(--grey-800);
  --neutral-900: var(--grey-900);
  --neutral-950: var(--grey-950);
}
[data-theme="dark"] {
  --bg-main: var(--grey-950);
  --bg-secondary: var(--grey-900);
  --navbar-color: #09090b95;
  --text-main: #ffffff;
  --text-secondary: var(--grey-100);
  --text-tertiary: var(--grey-200);
  --text-50: #ffffff50;
  --text-75: #ffffff75;
  --navbar: #00000095;
  --primary-color: var(--pink-500);
  --primary-color-light: var(--pink-400);
  --primary-color-dark: var(--pink-600);
  --secondary-color: var(--purple-500);
  --secondary-color-light: var(--purple-400);
  --secondary-color-dark: var(--purple-600);
  --error-bg: var(--red-900);
  --error-text: var(--red-200);
  --success-bg: var(--green-900);
  --success-text: var(--green-200);
  --neutral-50: var(--grey-950);
  --neutral-100: var(--grey-900);
  --neutral-200: var(--grey-800);
  --neutral-300: var(--grey-700);
  --neutral-400: var(--grey-600);
  --neutral-500: var(--grey-500);
  --neutral-600: var(--grey-400);
  --neutral-700: var(--grey-300);
  --neutral-800: var(--grey-200);
  --neutral-900: var(--grey-100);
  --neutral-950: var(--grey-50);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;  
}
h1, h2, h3, h4 {
  color: var(--text-main);
}
p, strong, em, li, div {
  color: var(--text-secondary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.material-symbols-rounded {
  user-select: none;
}