.section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 2rem;
}
.grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto, 1fr);
}

@media (max-width: 767px) {
    .grid {
        display: flex;
        flex-direction: column;
    }
}

.card {
    border: 1px solid var(--neutral-200);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    transition: border-color .3s ease-in-out;
}
.card:hover {
    border-color: var(--text-main);
}

.top {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.emoji {
    padding: .25rem;
    border-radius: .75rem;
    background-color: var(--neutral-100);
    width: fit-content;
    height: fit-content;
}
.emoji span {
    font-size: 1.75rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}