.spotlightCard {
    width: 6rem;
    height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    gap: .5rem;
    border: 1px solid var(--neutral-200);
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    font-size: .75rem;
    cursor: default;
}
.cardGroup {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 40rem;
    position: relative;
    overflow: hidden;
    transition: border-color 0.3s ease;
}