.button {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .9rem;
  font-weight: bold;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
a.button{
  text-decoration: none;
}
/* Sizes */
.small {
  padding: .25rem .5rem;
}
.medium {
  padding: .5rem 1rem;
}
.large {
  padding: .75rem 1.5rem;
  font-size: 1rem;
}
/* Border Radius */
.rounded {
  border-radius: 10rem;
}
.soft {
  border-radius: .5rem;
}
.hard {
  border-radius: 0;
}
/* Variant */
.border {
  border: 1px solid var(--color);
  background: transparent;
}
.border:hover:not(:disabled) {
  background: var(--color);
  color: var(--hover-text-color);
}
.border:active:not(:disabled) {
  background: var(--active-color);
  border-color: var(--active-color);
  color: var(--active-text-color);
}
.background {
  background: var(--color);
}
.background:hover:not(:disabled) {
  background: var(--hover-color);
  color: var(--hover-text-color);
}
.background:active:not(:disabled) {
  background: var(--active-color);
  color: var(--active-text-color);
}
.ghost {
  background: transparent;
}
.ghost:hover:not(:disabled) {
  background: var(--hover-color);
  color: var(--hover-text-color);
}
.ghost:active:not(:disabled) {
  background: var(--active-color);
  color: var(--active-text-color);
}
.button:disabled {
  cursor: not-allowed;
  background: #ececec;
  color: #565656;
}